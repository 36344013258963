import React, { useMemo, useCallback } from 'react';
import type { FC } from 'react';
import { Button, Flex, Modal, ModalContent } from '@lama/design-system';
import type { FormikHelpers } from 'formik';
import { Formik } from 'formik';
import type { Entity } from '@lama/common-types';
import type { RequirementProperty } from '@lama/contracts';
import type { ApplicationApiModel, OpportunityApiModel } from '@lama/clients';
import type { DialogMode } from '../../index.js';
import { formValuesToEntityPartial, getValidationSchema } from './genericFormUtils.js';
import { GenericPropertiesGrid } from './GenericPropertiesGrid.js';

interface GenericAddOrEditDialogProps {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => Promise<void>;
  initialValues?: Record<string, any>;
  mode?: DialogMode;
  itemProperties: RequirementProperty[] | undefined;
  entityType: Entity;
  parentFieldName: string;
  parentDisplayName: string;
  entity?: Record<string, any>;
  application: ApplicationApiModel;
  opportunity?: OpportunityApiModel;
  isLoading?: boolean;
}

export const GenericAddOrEditDialog: FC<GenericAddOrEditDialogProps> = ({
  open,
  onClose,
  onSubmit,
  initialValues = {},
  itemProperties = [],
  entityType,
  parentFieldName,
  entity = {},
  application,
  opportunity,
  isLoading,
}) => {
  const action = initialValues?.id ? 'Update' : 'Add';

  const validationSchema = useMemo(
    () => getValidationSchema({ properties: itemProperties, blockOnMissingRequiredFields: false, parentFieldName }),
    [itemProperties, parentFieldName],
  );

  const onSubmitInternal = useCallback(
    async (values: Record<string, any>, { resetForm }: FormikHelpers<any>) => {
      const partial = formValuesToEntityPartial(values, initialValues, itemProperties);
      await onSubmit({ ...partial, id: initialValues?.id });
      resetForm();
    },
    [initialValues, itemProperties, onSubmit],
  );

  return (
    <Modal open={open} onClose={onClose} title={`${action} Item`} alignTitle={'center'} size={'l'} fullWidth>
      <ModalContent scrollable>
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={onSubmitInternal} validateOnChange>
          {({ handleSubmit, isValid }) => (
            <Flex flexDirection={'column'} gap={12} width={'100%'}>
              <Flex gap={4} flexWrap={'wrap'} flexDirection={'row'}>
                <GenericPropertiesGrid
                  properties={itemProperties}
                  entityType={entityType}
                  entity={entity}
                  application={application}
                  opportunity={opportunity}
                />
              </Flex>
              <Flex flexDirection={'row'} justifyContent={'center'}>
                <Button variant={'primary'} color={'primary'} onClick={handleSubmit} disabled={!isValid} loading={isLoading}>
                  {action}
                </Button>
              </Flex>
            </Flex>
          )}
        </Formik>
      </ModalContent>
    </Modal>
  );
};
